<template>
  <div style="width: 100%" id="printReport">
    <header
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      "
    >
      <img
        src="@/assets/logoBlue.png"
        alt="[logo]"
        style="width: 600px; padding: 20px"
      />
      <div>
        <h5>
          {{ `Bpe Emitidos` }}
        </h5>
        <p>
          Período de: {{ convertDate(dateStart) }} à {{ convertDate(dateEnd) }}
        </p>
      </div>
    </header>

    <table style="text-align: center; width: 100%; margin-top: 40px">
      <thead>
        <tr style="text-align: center; color: #363636">
          <th
            style="border-bottom: 1px solid rgb(214, 214, 214); padding: 5px"
            v-for="(header, ih) in headers"
            :key="ih"
          >
            {{ header.text }}
          </th>
        </tr>
      </thead>
      <tbody v-for="(item, ind) in items" :key="ind">
        <tr style="border-bottom: 1px solid rgb(214, 214, 214)">
          <td style="padding: 5px">
            {{ item.origem }}
          </td>
          <td style="padding: 5px">
            {{ item.destino }}
          </td>
          <td style="padding: 5px">
            {{ Intl.NumberFormat("pt-BR").format(item.quantidade) }}
          </td>
          <td style="padding: 5px">
            {{ convertCurrent(item.valor) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import mixin from "@/mixins";
export default {
  props: ["items", "headers", "dateStart", "dateEnd", "agencia"],
  mixins: [mixin],
  create() {
    this.mixin;
  },
};
</script>
