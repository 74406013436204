<script>
import calendar from "@/components/Calendars.vue";
import mixin from "@/mixins";
import Print from "./components/PrintDataItems";
export default {
  components: {
    calendar,
    Print,
  },
  mixins: [mixin],
  data: () => ({
    headers: [
      { text: "UF Origem", value: "origem", sortable: false },
      { text: "UF Destino", value: "destino", sortable: false },
      { text: "Quantidade", value: "quantidade", sortable: false },
      { text: "Valor", value: "valor", sortable: false },
    ],
    search: {
      dateStart: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateEnd: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    },
    items: [],
  }),
  created() {
    this.mixin;
  },
  methods: {
    async loadValues() {
      this.$store.dispatch("activeLoader", true);
      const res = await this.$http.post("/relatorios/bpe", this.search);
      this.items = res.data;
      this.$store.dispatch("activeLoader", false);
    },
    printItem() {
      this.$htmlToPaper("printReport");
    },
  },
};
</script>
